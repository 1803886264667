<template>
    <div class="fail-wrap">
        <div class="content">
            <!-- <div class="icon-fail"></div> -->
            <img class="icon-fail" src="@/assets/common/ic_danger.svg" />
            <div class="fail-tip" v-show="showTitle">{{ $t('registerFail') }}</div>
            <div class="reason" v-html="reasonText"></div>
            <div class="back" @click="ok">{{ $t('ok') }}</div>
            <div class="close-tip" v-html="timeLeft"></div>
        </div>
    </div>
</template>

<script>
import useTime from '@/hooks/useEndTime';
export default {
    name: 'check-fail',
    props: {
        reason: {
            type: String,
            required: true,
        },
        showTitle: {
            type: Boolean,
            default: true,
        }
    },
    setup(props, context) {
        const params = {
            langKey: "timeLeft1",
            paramKey: "leftTime1",
            seconds: 10,
            endTimeEvent() {
                context.emit('update:isFail', false);
            },
        };
        let obj = useTime(params);
        let timeLeft = obj.timeLeft;

        const ok = () => {
            context.emit('update:isFail', false);
        }

        return {
            ok,
            timeLeft,
            reasonText: props.reason,
        }
    }
}
</script>
<style lang="scss">
.special-mode,.special-mode-header{
    .fail-wrap .content{
        top: auto !important;
        bottom: 20% !important;
        transform: translateY(0) !important;
    }
}
</style>
<style lang="scss" scoped>
.fail-wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.6);
    font-family: Barlow;
    z-index: 1000;
    .content {
        position: absolute;
        left: 1.1rem;
        right: 1.1rem;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 0.24rem;
        @include color-bg-primary('color3');
        box-shadow: 0 0.1rem 0.1rem 0 rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 0.3rem;
    }
    .icon-fail {
        margin-top: 1rem;
        width: 1.3rem;
        height: 1.3rem;
        // border-radius: 50%;
        // box-shadow: 0 0 0.12rem 0.16rem rgba(241, 0, 51, 0.1);
        // background: url(@/assets/image/ic_error.png) no-repeat 0 0 / 1.6rem 1.6rem;
    }
    .fail-tip {
        height: 0.6rem;
        line-height: 0.6rem;
        @include text-color('color-text-dark');
        font-weight: bold;
        font-size: 0.48rem;
        margin: 0.6rem 0 0;
    }
    .reason {
        font-size: 0.4rem;
        @include text-color('color-text-dark1');
        line-height: 0.56rem;
        text-align: center;
        margin: 0.5rem 0.6rem 0.8rem;
        white-space: pre-line;
    }
    .back {
        width: 400px;
        height: 80px;
        line-height: 80px;
        text-align: center;
        @include text-color('color3');
        background: var(--KT-Gradient-Red-01, linear-gradient(73deg, #8F0044 11.91%, #BC003C 37.3%, #E20030 63.49%, #FF1636 88.09%));
        border-radius: 40px;
        font-size: 32px;
        font-weight: 600;
    }
    .close-tip {
        margin-top: 0.3rem;
        opacity: 0.4;
        height: 0.5rem;
        line-height: 0.5rem;
        font-size: 0.24rem;
        @include text-color('color-text-dark');
        :deep span {
            font-size: 0.4rem;
        }
    }
}
</style>