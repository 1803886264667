<template>
  <a-modal class="opsdownloadModal" v-model:visible="taskModalVisible" :footer="null" :maskClosable="false" centered
           :closable="false">
    <div class="opsdownload-wrap"> 
      <div>
        <img src="@/assets/image/ic_danger.svg" />
      </div>
      <div class="opsdownloadTips" v-html="$t('opsdownloadTips')"></div>
    </div>
  </a-modal>
  <Notice v-if="hideNotice" :img="noticeImg" :content="noticeContent"/>
  <CommonNotice v-if="showCommonNotice" :style="commonNoticeStyle"/>
  <a-config-provider :locale="localeLang">
    <div id="all-page-wrap" :key="outerKey" :class="[
      'container',
      { 'no-footer': hideFooter },
      { pt3: !hideHeader },
      { 'special-mode': hideHeader && isSpecialMode },
      { 'special-mode-header': !hideHeader && isSpecialMode },
      { 'special-mode-custom-header': showHeaderForce && isSpecialMode },
      { 'bg-color': isSetPageBgColor },
    ]">
      <Header :showNotice="hideNotice || showCommonNotice" :title="headerTitle" :hide-home="hideHeaderHomeBtn" />
      <!--  <Notice v-if="hideNotice" /> -->
      <!-- 路由匹配到的组件将渲染在这里 -->
      <!-- <router-view></router-view> -->
      <!-- vue3.0配置 Component是固定写法-->
      <router-view v-slot="{ Component }">
        <!-- include属性 里面不是路由的名字 是组件的名字！！！！！！！！！！ -->
        <keep-alive :include="keepAlivePage.join(',')">
          <component :is="Component" :class="{'special-bg': !showHeaderForce && hideHeader && isSpecialMode}" />
        </keep-alive>
        <!-- <component :is="Component" :key="route.name" v-if="!route.meta.keepAlive"/> -->
      </router-view>
      <Footer v-if="!hideFooter" />
      <a-spin size="large" :spinning="Boolean($store.state.apiLoadingNum)"/>
      <ErrorTip v-if="isShowErr" v-model:isFail="isShowErr" :reason="errorText" :showTitle="Boolean(0)"/>
    </div>
    <ad></ad>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale/zh_CN'
import zhHK from 'ant-design-vue/lib/locale/zh_HK'
import enUS from 'ant-design-vue/lib/locale/en_US'
import Header from "@/components/layout/header.vue";
import Footer from "@/components/layout/footer.vue";
import moment from 'moment';
import 'moment/locale/zh-cn';
import {getLanguage, setLanguage} from '@/util/authentication'
import useFooter from '@/hooks/useFooter'
import useScanHKID from '@/hooks/useScanHKID'
import {ref} from '@vue/reactivity';
import {getCurrentInstance, onMounted, provide, watch} from '@vue/runtime-core';
import {useRouter} from "vue-router";
import i18n from "@/i18n";
import Notice from "@/components/layout/notice.vue";
import CommonNotice from "@/components/layout/commonNotice.vue"
import ErrorTip from '@/views/register/registerFail';
import ad from '@/components/ad'
import { useStore } from 'vuex'
import { t300Settle } from '@/http/api.js'
moment.locale(getLanguage());

export default {
  name: "App",
  components: {
    Header,
    Footer,
    Notice,
    ErrorTip,
    ad,
    CommonNotice
  },
  setup() {
    const router = useRouter();
    const Bus = getCurrentInstance().appContext.config.globalProperties.$bus;
    const store = useStore();
    let globalTimeout = 0;
    // 当前页面整体是否只展示一半
    const isSpecialMode = ref(false);
    // 全局错误提示
    const errorText = ref('');
    // 是否显示错误
    const isShowErr = ref(false);
    const isSetPageBgColor = ref(false);
    const noticeImg = ref('');
    const noticeContent = ref('');
    const showHeaderForce = ref(false)
    // 错误提示框 -- 子组件使用
    provide('showGlobalErr', val => {
      errorText.value = val;
      isShowErr.value = true;
    });
    provide('isSpecialMode', isSpecialMode);
    provide('changeMode', val => {
      isSpecialMode.value = val;
    });
    // 强制展示按照header的样式判断
    provide('showHeaderForceFunc', val => {
      showHeaderForce.value = val;
    });

    const showCommonNotice = ref(true);  // 是否显示CommonNotice
    const commonNoticeStyle = ref({}) // notice样式
    provide('setCommonNoticeFun', (style) => {
      commonNoticeStyle.value = style;
    });
    const hideNotice = ref(false);  // 是否隐藏Notice
    provide('hideNotice', hideNotice);
    provide('hideNoticeFun', () => {
      hideNotice.value = false;
    });
    provide('showNoticeFun', () => {
      hideNotice.value = true;
    });
    provide('setNoticeInfo', (img, content) => {
      noticeImg.value = img;
      noticeContent.value = content;
    });

    const outerKey = ref(0);
    const exceptPageRefreshArray = ['/programme', '/checkIn', '/login', '/register/registerHKID', '/selectDateTime', '/menu']
    provide('forceUpdate', () => {
      // programme首页 切换语言单独处理
      if (exceptPageRefreshArray.includes(router.currentRoute.value.path)) {
        Bus.emit('refresh');
        return;
      }
      outerKey.value += 1;
    });

    // 修改公共header标题
    let headerTitle = ref('');
    let hideHeaderHomeBtn = ref(false)
    provide('setHeaderTitle', title => {
      headerTitle.value = title
    });
    provide('setHeaderHomeBtnHide', hide => {
      hideHeaderHomeBtn.value = hide || false
    });

    const headerZIndex = ref(false);
    provide('headerZIndex', headerZIndex);
    provide('setHeaderZIndex', type => {
      headerZIndex.value = type;
    });

    // 当前选择的运动信息
    provide('setCurrSport', sport => {
      sessionStorage.setItem('selectedSport', JSON.stringify(sport || {}));
    })

    const systemInfo = ref({logoutTime: 600});
    provide('systemInfo', systemInfo);
    provide('setSystemInfo', sysInfo => {
      systemInfo.value = sysInfo;
      localStorage.setItem('logoutTime', sysInfo.logoutTime)
      localStorage.setItem('configlogoutTime', sysInfo.logoutTime)
    })

    const keepAlivePage = ref(['a']);
    provide('setKeepAlivePage', (isAdd, page) => {
      let currPage = new Set(keepAlivePage.value);
      isAdd ? currPage.add(page) : currPage.delete(page);
      keepAlivePage.value = [...currPage];
    });

    // 设置登录之后跳转的页面
    const showPageAfterLogin = ref('');
    provide('showPageAfterLogin', showPageAfterLogin);
    provide('setPageAfterLogin', route => {
      showPageAfterLogin.value = route;
    })
    // 重置倒计时
    provide('resetLogoutTime', data => {
      localStorage.setItem('logoutTime', systemInfo.value.logoutTime)
    })

    // 监听当前路由
    watch(
        () => router.currentRoute.value,
        n => {
          console.log('当前路由', n.name)
          globalTimeout = 0;
          if (n.name == 'home') {
            isSpecialMode.value = false;
          }
          if (n.name == 'home' || n.name == 'menu') {
            isSetPageBgColor.value = false;
          } else {
            isSetPageBgColor.value = true;
          }
          if (n.name == 'home' || n.name == 'menu' || n.name == 'selectSport' || n.name == 'programme') {
            store.commit('setHeaderBackgroundImage', undefined);
          }
          if (!hideNotice.value) {
            if (router.currentRoute.value.path == '/home' || router.currentRoute.value.path == '/') {
              localStorage.setItem('logoutTime', systemInfo.value.logoutTime)
              showCommonNotice.value = false;
            } else {
              showCommonNotice.value = true;
            }
          } else {
            showCommonNotice.value = false;
          }
        },
        {immediate: false}
    );

    // 监听当前路由
    watch(
        () => hideNotice.value,
        n => {
          if (!n) {
            if (router.currentRoute.value.path == '/home' || router.currentRoute.value.path == '/') {
              localStorage.setItem('logoutTime', systemInfo.value.logoutTime)
              showCommonNotice.value = false;
            } else {
              showCommonNotice.value = true;
            }
          } else {
            showCommonNotice.value = false;
          }
        },
        {immediate: false}
    );


    let enLang = getLanguage();
    const localeLang = enLang == 'en' ? enUS : (enLang == 'zh-hk' ? zhHK : zhCN);

    // 设置系统字体
    // const resize = () => {
    //   let width = document.documentElement.clientWidth;
    //   let ratio = width / 1080; // 高保真宽度 1080
    //   document.documentElement.style.fontSize = `${ratio * 100}px`;
    //   return;
    // };
    // resize();
    // window.onresize = resize();

    setInterval(() => {
      // globalTimeout = 0
      if (router.currentRoute.value.path == '/confirmBooking' || router.currentRoute.value.path == '/facilityBookingDetail') {
        globalTimeout = 0
      } else {
        globalTimeout += 1;
      }
      if (globalTimeout >= systemInfo.value.logoutTime && router.currentRoute.value.path != '/home') {
        // 设置默认语言
        // setLanguage("zh-hk");
        setLanguage('en', store);
        const {locale} = i18n.global;
        // locale.value = 'zh-hk';
        locale.value = 'en';
        router.push('/home');
        globalTimeout = 0;
      }
    }, 1000);

    window.addEventListener("mousemove", () => {
      globalTimeout = 0;
    })
    window.addEventListener("touchmove", () => {
      globalTimeout = 0;
    })
    window.addEventListener("touchstart", () => {
      globalTimeout = 0;
    })
    window.addEventListener("click", () => {
      globalTimeout = 0;
    })
    window.addEventListener("wheel", () => {
      globalTimeout = 0;
    })
    window.addEventListener("keydown", () => {
      globalTimeout = 0;
    })

    let {hideFooter, hideHeader} = useFooter(isSpecialMode);

    useScanHKID();
    // let scanInfo = useScanHKID();
    // provide('scanInfo', scanInfo);

    // 八达通下载黑名单定时任务
    let taskModalVisible = ref(false);
    const taskConfig = ref({
      time: '23:45:00', // 每天几点执行
      // time: '17:30:00', // 每天几点执行
      interval: 1, // 隔几天执行一次
      intervalTimer: '',
      timeOutTimer: ''
    })
    const octopusPayFliesTask = () => {
      // 获取定时任务时间: YYYY-MM-DD HH:mm:ss
      let endTime = `${moment().format("YYYY-MM-DD")} ${taskConfig.value.time}`;
      // 获取定时任务时间戳
      let end = moment(endTime).unix() * 1000;
      // 获取当前时间戳
      let start = moment().unix() * 1000;
      // 如果执行时间已经过了今天，就让把执行时间设到第二天的定时任务时间
      if (end <= start) {
        end += 24 * 60 * 60 * 1000;
      }
      //获取距离多少时间戳后执行
      let downCount = end - start; // 2 * 60 * 60 * 1000; 
      taskConfig.value.timeOutTimer = setTimeout(() => {
        octopusPayFliesTaskAction()
        t300SettleApi()
        // 只要不关闭软件，每隔interval天再执行
        const intTime = taskConfig.value.interval * 24 * 60 * 60 * 1000;  //2 * 60 * 60 * 1000; 
        taskConfig.value.intervalTimer = setInterval(() => {
          octopusPayFliesTaskAction()
          t300SettleApi()
        }, intTime);
      }, downCount);
    }
    // 八达通下载黑名单定时任务业务函数
    const octopusPayFliesTaskAction = () => {
      // 执行下载命令
      window.electronApi.exactlyTime('H');
      window.electronApi.payFile(); // 生成八达通支付文件
      setTimeout(() => {
        // 5分钟后执行上传交易文件指令
        window.electronApi.exactlyTime('E');
      }, 5 * 60 * 1000);
      // 下载和上传命令监听--系统维护遮罩显示、隐藏
      window.electronApi.exactlyTimeReply((event, res) => {
        console.log(res)
        if (res.data === 'start') {
          // 开始下载，添加遮罩
          taskModalVisible.value = true;
        }
        if (res.data === 'end') {
          // 结束下载，去除遮罩
          taskModalVisible.value = false;
        }
      })
    }

    const t300SettleApi = () => {
      t300Settle().then((res)=>{
        console.log('===t300Settle==response=',res)
      })
    }

    onMounted(() => {
      if (window.electronApi) {
        // 获取Mac地址
        window.electronApi.getIpcMac();
        window.electronApi.getIpcMacReply((event, res) => {
          console.log('====ipmac==res=====', res);
          if (res && res.data && res.data[0]) {
            localStorage.setItem('ip', res.data[0].address);

          }
        });
        window.electronApi.consoleReply((event, res) => {
          console.log('====electron===console==', res);
        });

        // 软件启动，开始执行八达通下载黑名单定时任务
        octopusPayFliesTask()
        // 全局监听点击事件（INPUT框点击，弹出自定义软键盘窗口）
        document.addEventListener('click', (e) => {
          // 下面组件不触发键盘
          let notDis = ['ant-radio-input', 'ant-checkbox-input']
          if (e.target && e.target.nodeName == 'INPUT' && !notDis.includes(e.target.className) && !e.target.disabled && !e.target.readOnly) {
            console.log('触发focus事件');
            window.electronApi.toggleKeyboard(true)
          }
        })
      }
    })
    return {
      hideFooter,
      hideHeader,
      showHeaderForce,
      localeLang,
      isSpecialMode,
      hideNotice,
      commonNoticeStyle,
      outerKey,
      errorText,
      isShowErr,
      isSetPageBgColor,
      keepAlivePage,
      noticeImg,
      noticeContent,
      showCommonNotice,
      systemInfo,
      taskConfig,
      t300SettleApi,
      octopusPayFliesTask,
      octopusPayFliesTaskAction,
      taskModalVisible,
      headerTitle,
      hideHeaderHomeBtn
    }
  },
};
</script>

<style lang="scss">
#app {
  height: 100%;
  // background: url('~./assets/image/top_bj.png') no-repeat 0 top / 100% 40%;
  font-family: Barlow-Medium, Barlow;

  &::before {
    // content: '';
    // position: absolute;
    // top: 0.5rem;
    // left: 0.5rem;
    // height: 0.8rem;
    // width: 1.78rem;
    // background-image: url('./assets/image/ktsp-logo.png');
    // background-size: 100% 100%;
  }

  .bg-color {
    background-color: #fff;
  }
}


.special-mode .select-date-mask,
.special-mode .drawer-wrap,
.special-mode .pop-wrap,
.special-mode .ant-spin-spinning {
  top: 0 !important;
  margin-top: 0;
}

.opsdownloadModal {
  width: 100% !important;
  // height: calc(100% - 72px) !important;
  height: 100% !important;
  position: absolute;
  vertical-align: top !important;
  top: 0px;
  max-width: none !important;
  .ant-modal-content {
    background: linear-gradient(12deg, #8F0044 2.46%, #BC003C 34.73%, #E20030 68%, #FF1636 99.26%);
    width: 100%;
    height: 100%;
    .ant-modal-body {
      height: 100%;
    }
  }
}
</style>
<style lang="scss" scoped>
@import '~@/assets/style/calc.scss';
.opsdownload-wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.opsdownloadTips {
  margin-top:40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  text-align: center;
  font-family: Barlow;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.container {
  overflow: scroll;
  position: relative;
  top: 0;
  width: 100%;
  height: calc(100% - 72px);

  &.no-footer{
    height: 100% !important;
  }

  &.pt3 {
    padding-top: calc(70px + ft(112));  // 112 header:回退+标题+home区域高度
  }

  &.special-mode {
    margin-top: 0;

    :deep .page-header {
      top: 30%;
    }
    .special-mode-top{
      padding-top: 30vh;
    }
  }

  .special-bg{
    padding-top: 30vh;
  }

  &.special-mode-header{
    padding-top: calc(30vh + ft(112));  // 112回退+标题+home区域高度
    ::v-deep {
      .page-head{
        padding-top: 30vh;
      }
      .header{
        top: 0 !important;
      }
    }
  }

  &.special-mode-custom-header{
    ::v-deep {
      .page-head{
        padding-top: 30vh;
      }
      .header{
        top: 0 !important;
      }
    }
  }

  :deep .ant-spin-spinning {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1000;

    .ant-spin-dot-spin {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}

</style>
