/*
 * @Descripttion: 
 * @Version: 1.0
 * @Author: licuixia
 * @Date: 2023-09-26 19:14:12
 * @LastEditors: licuixia
 * @LastEditTime: 2025-02-28 18:25:10
 */
import { reactive, getCurrentInstance } from "vue";
import telegramCode from "@/hooks/telegram-code.json";
export default function() {

  const scanInfo = reactive({
    hkidInfo: {},
    scanStatus: null,
    isScanError: false,
    errMessage: '',
  });

  const Bus = getCurrentInstance().appContext.config.globalProperties.$bus;

  if (!window.electronApi) {
    return scanInfo;
  }
  
  // 监听香港身份证读卡设备读卡结果返回
  window.electronApi.scanExeStatus((event, res) => {
    // console.log('扫描返回信息', res);
    if (typeof res == 'string') {
      res = res.replace(/(\\t|\\n)/g, '');
      res = JSON.parse(res);
    }
    let hkidObj = {};
    if (res.code == 0) {
      //扫描成功  获取data  保存数据  在6的时候进行下一步
      res.data = JSON.parse(res.data);
      // engName: "PENG, Shuai", dob: "26-10-1990", gender: "M", chnName: "1756 1596", docNo: "F188323(9)"
      // console.log('进来啦！！！-------------', res.data);
      // "docType": "HK_IDCARD", // 固定HK_IDCARD
      // "docNo": "V4870956", // 证件号
      // "engGivenName": "Wu",
      // "engSurname": "junliang",
      // "dob": "1997-01-01",
      // "gender": "M",
      if (res.data.engName) {
        let name = res.data.engName.split(',');
        hkidObj.engSurname = name[0] ? name[0].trim() : ''; // 姓
        hkidObj.engGivenName = name[1] ? name[1].trim() : ''; // 名
      }
      if (res.data.dob) {
        hkidObj.dob = res.data.dob.split('-').reverse().join('-');
      }
      if (res.data.gender) {
        hkidObj.gender = res.data.gender;
      }
      if (res.data.docNo) {
        hkidObj.docNo = res.data.docNo;
      }
     
      if (res.data.chnName) {
        // hkid中文名码转中文
        hkidObj.chnName = res.data.chnName.split(' ').map(el => telegramCode[el]).join('');
      }

      // scanInfo.hkidInfo = hkidObj;
      console.log('setScanInfo====', hkidObj)
      Bus.emit('setScanInfo', hkidObj)
    } else if (res.code == 2) { //报错
      // scanInfo.scanStatus = 0;
      Bus.emit('setScanInfo');
    } else if (res.code == 3) { // 身份证号码不正确
      // scanInfo.scanStatus = 0;
      // scanInfo.errMessage = this.$t("checkin_tips1");
      Bus.emit('setScanInfo');
    } else if (res.code == 4) { // 纸质身份证
      // scanInfo.scanStatus = 0;
      // scanInfo.errMessage = this.$t("checkin_tips2");
      Bus.emit('setScanInfo');
    } else if (res.code == 5) {
      // 5.身份证已经放入
      // scanInfo.scanStatus = 1;
    } else if (res.code == 6) {
      //6.身份证已经拿开
      // if (Object.keys(scanInfo.hkidInfo).length) {
        // scanInfo.scanStatus = 0;
        // scanInfo.hkidInfo = {};
        // this.confirm('HKID',res.data.docNo,res.data.mac);
      // }
      window.electronApi.openOcr();
    } else {
      // scanInfo.errMessage = res.message;
      Bus.emit('setScanInfo');
    }
  });
  
  setTimeout(() => {
    window.electronApi.openOcr();
  }, 1000)

  return scanInfo;
}