export default {
    facility: "設施",
    venus: "主場地",
    balloting: "抽籤",
    book: "預訂",
    selectDate: "選擇日期",
    selectYourFriend: "選擇您的好友",
    age: "年齡",
    location: "地址",
    sex: "性別",
    selectCourt: "社區公共運動場",
    selectCourt_price: "$120每小時",
    roomA: "房間A",
    roomB: "房間B",
    roomC: "房間C",
    available: "可預約",
    select: "已選擇",
    unavailable: "不適用",
    unavailable1: "不適用",
    lessThan7days: "所選內容現在不可用，請在{cchfcfs}天內預訂場地",
    peakHour: "繁忙時段",
    selectCourt_tips:
        "會場內空調正在維修中，不便之處，敬請諒解",
    tapYourOctopus: "輕觸您的八達通",
    scanQRcode: "掃描您的支付寶二維碼",
    tapYourCreidtCard: "輕觸您的信用卡",
    paymentFailed: "支付失敗",
    countryName: "中國",
    bookingDetail_addPartner: "添加搭檔",
    bookingDetail_addPartner_btn: "添加一個搭檔",
    bookingDetail_addEquipment: "添加設備",
    bookingDetail_sRate_txt: "標準價格",
    bookingDetail_cRate_txt: "設備故障率",
    bookingDetail_equipment_txt: "設備",
    bookingDetail_coupon_txt: "優惠券",
    bookingDetail_subtotal_txt: "小計",
    bookingDetail_details_txt: "詳情",
    bookingDetail_tips1: '至少',
    partnerLimit: "至少{num}個隊友",
    confirm: "確認",
    programmeDetail_Introduction: "介紹",
    cancel: "取消",
    backToHome: "回到首頁",
    reselect: "重新選擇",
    cnacelAndLogout: "取消並退出",
    thanksForOrdering: "感謝訂購",
    takeReceipt: "請拿好您的收據",
    timeLeft: "在<span>{leftTime}</span>秒內沒有任何操作則關閉",
    ordering_tip:
        " 說明：您可以登錄網站或應用程序查看您的訂單。",
    timeLeft1: "<span>{leftTime1}</span>秒關閉",
    programmeDetail_lesson: "課程表",
    programmeDetail_age: "年齡",
    programmeDetail_date: "日期",
    programmeDetail_location: "地址",
    programmeDetail_selectClass: "選擇類別",
    programmeDetail_enrolled: "登記",
    programmeDetail_selectcoupon: "選擇優惠券",
    programmeDetail_selectcoupon_tips: "您有{couponnum}個可用優惠券",
    programmeDetail_more: "更多",
    programmeDetail_title: "預訂詳情",
    programmeDetail_id: "預訂編號",
    programme: "社區活動列表",
    programmeSport: "課程",
    sport: "體育運動",
    Sport: "體育運動",
    sports: "體育運動",
    month: "月份",
    programmeDetail_coach: "教練",
    coach: "教練",
    filter: "篩選",
    more_filter: "篩選",
    searchPlaceholder: "請輸入體育運動、教練...",
    pickSport: "體育設施列表",
    selectSports: "選擇運動",
    selectMonth: "選擇開始月份",
    viewAll: '查看全部',
    enroll: '現在報名',
    viewMore: '查看更多',
    enjoyInKaiTak: '在啟德盡情享受',
    checkIn: '簽到',
    selectOneSport: '選擇一項運動',
    programmeDetail_time: "時間",
    programmeDetail_price: '價格',
    bookingDetail_discount_txt: "折扣",
    contact_us: "聯絡我們：",
    menu: "菜單",
    home: "首頁",
    minutes: '備忘錄',
    total1: '總數',
    results: "條結果",
    loginTip: "登入",
    login: '登入',
    back: '返回',
    Back: '返回',
    realNameTop: '實名驗證',
    realNameTip: '抽籤必須是實名制的。實名信息僅用作活動的認證數據，啓德不會用於其他目的。',
    password: "密碼",
    hkId: "香港身份證",
    emailPhone: "郵箱/手提號碼",
    registerByIam: "iAM Smart註冊",
    loginByIam: "iAM Smart登入",
    byIam: "使用iAM Smart",
    scanHkId: "掃描您的香港身份證",
    scanHkId1: "掃描您的香港身份證",
    or: '或者',
    scanTip: "將您的香港身份證放在掃描區，如下所示",
    noAccount: "新用戶？",
    register: "註冊",
    hk_id: '身份證號',
    birthday: "生日",
    phone: "手提號碼",
    phoneNo: '手提號碼',
    name: '姓名',
    enterId: "輸入您的郵箱/手機號碼",
    enterPsd: "密碼",
    confirmPsd: "密碼確認",
    errorAccount: "郵箱地址或者手提號碼錯誤",
    errorAccountEmail: "請輸入有效的電子郵寄地址",
    errorAccountPhone: "請輸入有效的手提號碼",
    errorPwd: "您的密碼必須包含:* 8個或更多字符;*大小寫字母;* 3個特殊字符。",
    differPsd: "密碼與新密碼不匹配",
    duplicateEmailOrPhone: "郵箱或者手提號碼已存在",
    hkidHasUsed: "該香港身份證已經被註冊",
    viewDetail: "登錄網頁端或者APP查看詳情",
    loginNow: "該香港身份證已經註冊，正在登錄",
    scanQrCode: "掃描二維碼",
    presentQRcode: "出示您的付款二維碼",
    invalidQRcodeTips: '二維碼無效或未到的簽到時間。',
    tryAgain: "重試",
    stepBelow: "請根據以下步驟操作:",
    openIam: "1.在你的手機上打開iamSmart應用;",
    clickScanBtn: "2.點擊掃描按鈕;",
    scanPage: "3.掃描頁面上的二維碼;",
    downloadIam1: "沒有iamSmart應用請下載",
    downloadIam2: "還沒有iamSmart應用,掃描二維碼馬上下載!",
    idPlaceholder: "輸入您的郵箱/手機號碼",
    ssoEmailPlaceholder: "email{'@'}email.com",
    ssoEmail: "電郵地址",
    ssoPhone: "手機號碼",
    ssoPhonePlaceholder: "輸入手機號碼",
    ssoEmailTab: "電郵地址",
    ssoPhoneTab: "手機號碼",
    checkInTip: "掃描您的香港身份證或者二維碼",
    checkInTip2: "在掃描區域放入您的應用程序二維碼或者香港身份證",
    checkInSucc: "簽入成功！",
    checkInFail: "簽入失敗",
    timePending: "等待簽入",
    scanAgain: "掃描失敗，請重新嘗試掃描香港身份證",
    failReason: "對不起，沒有找到您的預訂信息",
    printReceipt: "打印收據",
    amount: "金額",
    notice_txt1: "請在  時間內完成訂單支付",
    tips_txt1: "在  時間內沒有操作則關閉 ",
    payCountdown: "請在 <span>{num}</span> 分鐘內完成您的預訂",
    tips_txt2: "  秒關閉",
    seconds: " 秒",
    print_txt: "打印中...",
    print_fail: "打印失敗",
    print_wrongdes: "對不起，打印失敗的原因是 ",
    print_sucess: "打印成功",
    print_take_receipt: "請拿好您的收據。",
    payment_wrongdes: "對不起，付款失敗的原因是 ",
    payment_change_method: "修改付款方法",
    payment_select_method: "選擇付款方法",
    payment_method_txt1: "信用卡",
    payment_method_txt2: "支付寶/微信支付",
    payment_method_txt3: "八達通支付",
    noActivity: "今天沒有活動",
    ok: "確定",
    justOneHour: "對不起，高峰時段只有一個小時可用",
    close: "關閉",
    programme_title: "活動詳情",
    quota: "名額",
    venue: "場地",
    left: "剩餘",
    leftNum: "剩餘名額 : {num}",
    lessonInfo: "{num} 節課",
    ageRange: "{min}-{max} 歲",
    bottomPrice: "費用",
    ageAbove: "{age} 歲以上",
    ageBelow: "{age} 歲以下",
    selectCoach: "選擇教練",
    searchCoachName: "選擇一個教練的名字",
    searchProgramme: "請輸入運動名稱，教練名稱...",
    search: "搜索",
    sportsType: "運動類型",
    clearAll: "全部清除",
    enroll_now: "現在報名",
    book_now: "現在預訂",
    venue_Details: "場地詳情",
    mainStadium: "主場館",
    change: "更換",
    full: "已滿",
    operation: "活動",
    hotline: "電話熱線",
    lesson: "{num} 課時",
    lessons: "{num} 課程",
    parQ_age_limit: "您預訂的項目需要參與問卷調查，請到web或者app中完成後再進行預訂",
    parQ_answer_all: '請回答所有問題',
    parQ_tips_option1: "我確認我已經過醫生檢查，並證明我的身體狀況適合參加這些活動。",
    parQ_tips_option2: "需要選擇出示健康證明書，以證明本人身體健康，可參加員工的健身活動。我要到櫃檯去進一步核實一下。取消確認。",
    parQ_title70: "身體活動問卷調查(PAR-Q&You)",
    parQ_tips_option70_1: "我經常參加體育活動，有能力參加體育活動。因此，我不需要出示任何醫學證明來證明我有能力參加本次活動，我明白如果我對自己的能力有任何懷疑，我應該在參加活動前咨詢醫生。",
    parQ_tips_option70_2: "我不經常參加體育活動。但是，我已經接受了醫生的檢查，並證明我的身體狀況適合參加本次活動，我將在申請提交期間上傳一份醫療證明。",
    parQ_tips_option70_3: "我特此確認：",
    parQ_tips_option70_4: "我已經閱讀、理解並完成了這份問卷。我的任何問題都得到了令人滿意的回答。",
    parQ_tips_decline: "拒絕",
    parQ_tips_agree: "確認並同意",
    virtual_queue_title: '虛擬排隊',
    virtual_queue_tips1: '您在一個虛擬隊列中。',
    virtual_queue_tips2: '人很多，請耐心等待...',
    virtual_queue_tips3: '抱歉久等',
    virtual_queue_tips4: '目前需求量非常大，因此您可能需要比平時等待更長的時間才能確認預訂請求。',
    checkin_tips1: "身份證號碼不正確",
    checkin_tips2: "紙質身份證",
    courtPrice: "${num}/小時",
    yes: "是",
    no: "否",
    reg_declaration: "註冊聲明",
    reg_scan: "請通過掃描香港身份證填寫以下表格",
    reg_scan_tip: "將您的香港身份證放在掃描區，如下所示",
    manuallyEnter: "手動輸入",
    register1: '註冊',
    confirmInfo: "確認您的信息",
    registerTip: '*該聯繫人將用作您的登錄帳戶。',
    registerByHKID: "香港身份證註冊",
    registerByEmail: "郵箱/手提號碼註冊",
    registerAction: '執行註冊',
    emailVerification: "郵箱驗證",
    finishSoon: "很快即將完成！",
    oneStep: "還有一步即可成爲KAITAK會員。",
    emailAddr: "電郵地址",
    emailPh: "輸入您的電郵地址",
    verCodeErr: "驗證碼錯誤或超時。",
    hasSend: "驗證碼已發送到您的電子郵箱 {email}, 請輸入",
    registerFail: "註冊失敗",
    regSucc: "註冊成功！",
    sendPassword: "初始密碼已發送到電子郵件地址 <span>{email}</span>.",
    login_web: "爲了您的帳戶安全，請登錄網站更改密碼並補充您的個人信息。",
    emailErr: "電郵地址錯誤。",
    send: "發送",
    sending_limited: "同一個郵箱發送超出最大限制。",
    emailOccupied: "您的郵箱已經被佔用，您可以通過忘記密碼流程找回賬號。",
    serverErr: "系統錯誤，請稍後再試。",
    print_paper_will_run_out: "紙將用完。",
    print_failed: "打印失敗。",
    swipe_octopus: "刷您的八達通卡",
    retry_please: "請重試",
    octopus_swipe_tip_1: "請把您的八達通放在卡片區",
    octopus_swipe_tip_2: "",
    octopus_swipe_tip_3: "無操作會自動取消",
    octopus_swipe_tip_4: "請再次拍卡！",
    octopus_swipe_tip_5: "交易未能完成。請再次拍卡！",
    octopus_error_tip_1: "此卡失效。請使用另一張八達通卡！",
    octopus_error_tip_2: "卡內餘額不足，交易取消。請先行增值！",
    octopus_error_tip_3: "卡上儲值額超出上限，請使用另一張八達通卡！",
    octopus_error_tip_4: "機器故障。 請聯繫工作人員尋求幫助！",
    link_octopus: "正在連接八達通…",
    tap_octopus: "放上八達通",
    confirmation_successful: "確認成功。",
    declaration_title: "設施預訂聲明",
    login_input_username_password: "請輸入 郵箱/手提號碼 或者 密碼",
    login_repeat_submit: "請勿重複提交",
    firstName: "名字",
    name_is_requeird: '此欄位為必填項。請確保輸入的姓名與身份證明名件的姓名相符。',
    lastName: "姓氏",
    gender: "性别",
    idCardType: "證件類型",
    idCardNum: "證件號碼",
    birth: "生日",
    notEmpty: "{property}不能爲空",
    male: "男",
    female: "女",
    any: "不限",
    other: '其他',
    scanFailed: "身份證賬號掃描獲取失敗",
    noCourt: "無可預訂時段",
    searchMember: "查詢會員名稱",
    enterVcode: "輸入驗證碼",
    noData: "未查詢到數據",
    morning: "早上時段",
    afternoon: "下午時段",
    evening: "晚上時段",
    avail: "剩餘",
    normal: "正常",
    less: "較少",
    partnerUpperLimit: "您最多隻能添加{num}個夥伴",
    partnerLowerLimit: "您至少需要添加{num}個夥伴",
    duplicatePartner: "您已經添加了{partner}",
    deleteFail: "刪除失敗",
    selectRate: "特殊人群優惠選項",
    class: "班級",
    classDetail: "班級詳情",
    imgClassTips: "張圖片",
    selectSport: "運動場所",
    Venue: "場地",
    Fee: "費用",
    selectVenue: "選擇場地",
    guardian: "監護人",
    eCouponCode: "電子優惠券碼",
    this_coupon_code: "此优惠码无效或已过期。",
    eCouponCodePlaceholder: "請輸入優惠碼",
    serialNo: "券碼",
    "facility_apply": "申請",
    "facility_check": "兌換",
    valid: "有效期",
    contactName: "聯系人姓名",
    relationship: "關係",
    guardianPhone: "監護人電話",
    guardianEmail: "監護人郵箱",
    add: "添加",
    delete: "刪除",
    selectRateType: "價格選項",
    confirmBooking: "確認預訂",
    nameLength: "長度為1-32個字符",
    contactNamePlaceholder: "請輸入監護人姓名",
    relationshipPlaceholder: "請選擇監護人關係",
    guardianPhonePlaceholder: "請輸入監護人電話",
    guardianEmailPlaceholder: "請輸入監護人郵箱",
    guardianEmailPlaceholderType: "請輸入正確的郵箱地址,長度為1-128個字符",
    phoneLength: "請輸入8位純數字",
    contactPhone: "聯系人電話",
    contactEmail: "聯系人郵箱",
    completeOrder: "完成訂單獲得",
    points: "積分",
    regAgreement: "KAITAK註冊協議",
    checkAgreementRegConfirm: "在確認前請先勾選KAITAK註冊協議",
    bookingRegAgreement: "條款及細則",
    regAgreementTips: "請仔細閱讀",
    regAgreementTips1: "我已閱讀並接受條款和條件",
    enterEmailOrPhone: "輸入您的郵箱/手提號碼",
    enterEmail: "輸入您的郵箱",
    enterAny: "輸入您的{label}",
    enterPhone: "輸入您的手提號碼",
    question: "問題",
    YES: "是",
    NO: "否",
    selectPaymentMethod: "選擇付款方法",
    creditCard: "信用卡",
    fps: "快速支付 / 支付寶 / WeChatpay / 滙豐",
    payMethod: "付款方法",
    bookingNotes: "預訂須知：",
    booking_Note: "注意事項：",
    bookingNotes_1: "1.每次預訂只能預訂一個場館。在高峯時段，最多可預訂2小時的場地，而在非高峯時段，最多可預訂4小時的場地。一旦您的預訂得到確認，我們將不會退還任何退款。",
    bookingNotes_2: "2.非吸菸場所嚴禁吸菸。",
    bookingNotes_3: "3.請穿着合適的運動服和運動鞋參加比賽。",
    bookingNotesCon: "每次預訂限制爲1個場館，高峯時段每個球場限制爲2小時，非高峯時段限制爲4小時。一旦預訂，恕不退款，請小心！2.禁止吸菸的場所，嚴禁在場所內吸菸。請穿着合適的運動服和運動鞋比賽。",
    selectPaymentMethodTips: "請選擇付款方法。",
    receiptNo: "收據號碼",
    bookingNumber: "預訂編號",
    octopus: "八達通",
    logout: "登出",
    notes: "須知",
    checkInTimeStartTip: "預訂時間從",
    checkInTimeEndTip: "通過掃描二維碼開始。 您可以登錄網站或應用程序查看您的預訂詳情。",
    paymenCodeTip: "請掃描二維碼以啓動付款流程。付款成功處理後，單擊“確認”按鈕完成預訂。",
    payment: "付款",
    bookingNotes1: "您的個人信息僅用於註冊爲啓德體育公園會員。啓德體育樂園不會將您的個人資料用於其他目的。一個身份可以註冊一個帳戶。",
    bookingNotes2: "非會員登錄將自動註冊爲會員。",
    loginNote: "所有收集的個人資料將僅用於註冊目的。除非法律允許或要求，否則未經您事先同意，啓德體育公園不會向任何第三方披露您的個人資料。",
    guardian_name: "監護人姓名",
    ballot_history_notes: "以下是對抽簽結果的各種解釋，每次預約僅限於一個場地，高峰時段每個場地限製2小時，非高峰時段限製4小時。一經預訂，恕不退款，請謹慎使用!2. 場地禁止吸煙，場內嚴禁吸煙。",
    addEquipment: "添加器材",
    pointsDeduction: "*每{point}積分可以抵扣HK${price}",
    playerName: "姓名",
    phoneNumber: "手提號碼",
    email: "電郵地址",
    memberId: "會員ID",
    addPlayer: "添加參加人",
    programmeDetailSelect: "選擇",
    emergencyContact: "緊急聯系人",
    ktspPoints: "KTSP 積分",
    total: "總計",
    confirmDelTips: "您想要刪除這條數據嗎?",
    multipleSport: "此運動需要多人蔘與，請前往web端預訂。",
    noVenue: "未查詢到場館或房間",
    peakHourLimit: "高峰時段的預訂超過了上限，請點擊「確定」按鈕以繼續您的預訂。",
    notPeakHourLimit: "非高峰時段的預訂超過了上限，請點擊「確定」按鈕以繼續您的預訂。",
    HourLimit: "預訂的時段超過了上限，請點擊「確定」按鈕以繼續您的預訂。",
    onlyOneCourtPerPeriod: "每個時間段只能選擇一個場地",
    onlyOneCourtForBallot: " 每個人每個時段只能參與抽籤一次。",
    oneCourtLimit: "請至少選擇一個場地及時段。",
    withoutCheckIn: "由於您多次預訂成功未簽到，當前無法預訂場地。",
    courtNeedSameDay: "您只能選擇同一天的場地",
    uncheckParQ: "請勾選parQ信息!",
    noMoreCoach: "沒有更多了",
    howtoBallot: "如何進行抽簽",
    ballotingHistory: "抽簽歷史",
    ballotingDes1: "使用您的HKID或iAM Smart驗證您的真實姓名，即可參與投票過程。 選擇您喜歡的日期和時間，然後提交您的條目。 一旦投票結果出來，獲勝者將收到一封電子郵件通知，允許他們繼續付款並確認預訂。",
    ballotingDes2: " 或者 ",
    ballotingDes3: "。 選擇你喜歡的課程，點擊 ",
    ballotingDes4: " 按鈕",
    enroll_ballot: "抽簽報名",
    ballotingTips: "*抽簽必須是實名，請掃描您的HKID或使用iAM Smart登錄。",
    programmeBallot: "課程抽簽",
    ballotNotice: "請核對信息，VBS只會將以下顯示信息保存為註冊數據。",
    guardian1: '監護人1',
    guardian2: '監護人2',
    partnerLimitWarnTxt: "參加人數不能大于{max}",
    realnameBookTips: "預訂前，請先登入啟德體育園網站或手機應用程式，並完成實名認證，讓您輕鬆享用我們的各項設施及服務。",
    leastOneGuar: "至少要有一個監護人",
    noConfigAmount: "該課程未配置支付金額信息",
    facilityBallot: "預訂抽籤",
    ballotingDetails: '抽籤訊息',
    announceDate: '公佈日期',
    submitSccessful: '提交成功!',
    language: '語言',
    languages: '語言',
    importantInfo: '重要訊息',
    howToPlay: '如何玩',
    selectTime: '選擇時間段',
    idNo: '證件號',
    ballotingTip: '抽籤結果將於 ',
    ballotingTip1: '公佈。要查看您的投票詳情和結果，請登錄我們的網站或應用程序。',
    balloting_download: '下載應用程序以探索更多體育項目並瞭解最新資訊。',
    downloadApp1: '下載應用程序',
    downloadApp2: '以瞭解更多信息並獲取最新訊息',
    vote: '*抽籤必須是實名制，請掃描您的香港身份證或通過iAM Smart登入。',
    confirmBalltoing: '請檢查訊息。 VBS將只保存顯示的以下內容。',
    ballotingResult: '抽籤結果',
    ballotingDate: '抽籤日期',
    bookingFailed: '預訂失敗',
    bookingFailedReason: '對不起，預訂失敗的原因是未配寘價格',
    viewResult: "查看结果",
    confirmBallotTitle: "確認您的抽簽",
    general_label_is_requeird: "{label}是必填項",
    realNameBookingTips: "此場地需要香港身份證。 請在預訂前完成身份驗證過程。",
    realNameBookingTipsSports: "選定的體育項目需要香港身份驗證，請在預訂前完成驗證過程。",
    queueTimeout: "排隊超時，請重新排隊",
    Repay: "重新支付",
    declarParq: "體能活動適應能力問卷 (PAR-Q)",
    parqYesTips: "Par-Q信息未滿足預訂要求，無法繼續預訂",
    addOnsFee: "附加設備費用",
    requiredTips: "必填信息",
    noMoreData: '沒有更多數據了',
    time_am: '上午',
    time_pm: '下午',
    noAgeLimit: '無年齡限制',
    thankPay: '感謝您的',
    checckPay: '請檢查您的電子郵件，了解',
    bookDetail: '您預訂的詳細訊息。',
    exit: '退出',
    continue: '繼續',
    checkEmailTips: '請查看您的電子郵件以瞭解您的預訂詳細資訊。',
    logout_upcase: "退出",
    welcome_username: '歡迎! ',
    hi_username: "嗨，{username}。",
    reminder_time: "你尚餘{remderTime}分鐘完成預訂。",
    octopusErrorTips1: '機器故障。請與本店職員聯絡。',
    octopusErrorTips2: '此卡失效。請使用另一張八達通卡。',
    octopusErrorTips3: '卡內餘額不足，交易取消。請先行增值。',
    octopusErrorTips4: '卡上儲值額超出上限，請使用另一張八達通卡。',
    octopusErrorTips5: '請再次拍卡。',
    octopusErrorTips6: '錯卡，請重試(八達通號碼{num})。',
    octopusErrorTips7: '讀卡錯誤，請重試。',
    payTimeOut: "我們無法處理您的付款。 請稍後再試。",
    errorPwdOrEmail: "你輸入的電郵地址 / 手提號碼或密碼錯誤。",
    errorGuardiansTips: "請填寫監護人的資訊以完成預訂。",
    errorEmergencyContactTips: "請填寫緊急連絡人資訊以完成預訂。",
    posPaymentCode: {
        '00': '交易獲得批准。',
        '01': '呼叫卡中心獲取審批碼。',
        '02': '呼叫卡中心獲取審批碼。',
        '03': '請查詢卡中心',
        '04': '沒收此卡',
        '05': '不接受',
        "08": "操作完成",
        "12": "請查詢卡中心",
        "13": "金額無效",
        "14": "請查詢卡中心",
        "19": "請重新操作",
        "25": "請查詢卡中心",
        "30": "請查詢卡中心",
        "31": "請查詢卡中心",
        "41": "請查詢卡中心",
        "43": "請查詢卡中心",
        '51': '交易拒絕。',
        "54": "此卡已失效",
        "55": "私人密碼錯誤",
        "58": "請查詢卡中心",
        "60": "請查詢卡中心",
        "76": "請查詢卡中心",
        "77": "核對錯誤",
        "78": "無此賬單",
        "80": "請查詢卡中心",
        "85": "請查詢卡中心",
        "88": "操作完成",
        "89": "請查詢卡中心",
        "91": "請查詢卡中心",
        "94": "請查詢卡中心",
        "95": "處理中",
        "96": "其它原因",
        "AP": "授權號碼",
        "BE": "機 座 故 障",
        "BL": "電池用量不足",
        "BT": "資料錯誤請重試",
        "CB": "請 執 行 結 算",
        "CN": "操 作 取 消",
        "DE": "資料錯誤請重試",
        "EC": "收銀機通訊故障",
        "FF": "交易拒絕",
        "HO": "接線不通,請重試",
        "IK": "資料錯誤請重試",
        "IM": "通訊錯誤,請重試",
        "IP": "通訊錯誤,請重試",
        "IR": "通訊錯誤,請重試",
        "IS": "通訊錯誤,請重試",
        'IT': "通訊錯誤,請重試",
        "LC": "通訊錯誤,請重試",
        "LN": "沒 有 撥 號 音",
        "LO": "線 路 繁 忙",
        "MA": "不 接 受 此 卡",
        "NB": "無交易總額",
        "NC": "接線不通,請重試",
        "NE": "不能使用此交易",
        "NG": "負交易總額",
        "NJ": "此交易不能調整",
        "NS": "不能執行銷售",
        "NT": "無 交 易 記 錄",
        "OL": "調整超過限額",
        "OT": "操 作 取 消",
        "PF": "私人密碼錯誤",
        "RE": "讀卡錯誤",
        "SB": "不接受",
        "SC": "讀卡錯誤, 請刷卡",
        "SE": "系統故障",
        "TA": "接 受",
        "TO": "通訊錯誤,請重試",
        "UC": "不接受此卡",
        "UL": "低於分期限額",
        "VD": "交 易 已 取 消",
        "VS": "請 核 對 簽 名",

        // '41': '卡丟了。',
        // '43': '偷來的信用卡。',
        // '77': '這發生在香港銀行主機上，不會批量上傳和列印結算單。 ECR應將此視為批量加載情况',
        // '78': '找不到指定的記錄',
        // 'CB': '請結算，因為記錄記憶體已滿',
        // 'CN': '被接線員取消了。',
        // 'LC': '通信失敗。',
        // 'NC': '連接失敗。',
        // 'NJ': '不允許調整',
        // 'NT': '無批量交易',
        // 'OT': 'S9000輸入超時。',
        // "SE": "系統异常",
        // 'TO': '響應超時。',
        // 'UC': '不支持的卡片類型',
        // 'VD': '交易無效',
        'BB': '終端忙或不可用。',
        'RF': '密碼失敗',
        'NA': '不允許授權',
        'NM': '不允許手動輸入',
        'NO': '不允許線下銷售',
        'NR': '不允許退款銷售',
        'NV': '不允許無效銷售',
        'AN': '無法找到主機或收購方',
        'OR': '設計是否執行覆蓋事務',
        'NP': '表明打印機沒有紙，並要求取消',
        'XX': '手機沒有放在基座上',
        'ME': 'MAC的錯誤',
        'EP': '終端處於反轉等待模式',
        "PYE": "支付類型錯誤",
        "OCF": "創建支付連接异常",
    },
    errorIamSmartSq: '未收到消息，請重試。',
    selectPayMethod: '結帳前請先選擇付款方式。',
    proceedCheckout: '去結算',
    unCompleteEmergency: '在結算前請先完成緊急連絡人資訊。',
    unCompletecheckParQ: "在結算前請先勾選parQ資訊。",
    unCompleteGuardiansTips: "在結算前請先完成監護人的資訊。",
    unCompletecheckParQConfirm: "在確認前請先勾選parQ資訊。",
    checkAgreementConfirm: '在確認前請先勾選KAITAK預訂協議。',
    existYesParQ: '強烈建議對PAR-Q回答“是”的參與者在繼續之前尋求醫療指導。你確定要繼續嗎？',
    booking_summary: "預訂詳情",
    "booking_slots": "預訂時段",
    no_more_data: '沒有更多數據了',
    loading_data: '加載中',
    check_out: "付款",
    Check_Out: "付款",
    facility_menu: "體育設施預訂",
    programmeSport_menu: "社區活動",
    checkIn_menu: "簽到",
    points_earned: "積分賺取",
    "Select_Time": "選擇時間",
    submit: "提交",
    back_txt: "返回",
    bookingChangeTime: {
        threeTime: '每次最多可預訂3個同一天的時段，其中僅限1個高峰時段。',
        clickTips: '點擊「確認」繼續預訂，先前選擇的時段將被清除。'
    },
    available_tomorrow: "明天可訂",
    facility_choose_session: "選擇時段",
    notes_enrolment: {
        title: "報名須知",
        desc1: "1.用戶可透過啟德體育園預訂系統遞交運動設施的抽籤申請。",
        desc2: "2.每名用戶於每個抽籤日期只可遞交一個運動設施一個時段的申請。",
        desc3: "3.如有重複遞交表格或填報資料不齊者，其抽籤資格將被取消。",
        desc4: "4.中籤者须于指定期限内透過指定網站或手機軟件完成付款手續，逾期未完成付款當中籤者自願放棄處理。"
    },
    programme_choose_class_tip: '請選擇班級',
    facility_full: "已滿",
    facility_available: "可預訂",
    facility_shortage: "名額有限",
    opstips: '交易正在進行中，請稍候...',
    opstips2: '付款尚未成功，參攷程式碼100022，<br>請重試或取消此付款請求',
    opsdownloadTips: '數據正在同步。<br>請勿執行其他操作。',
    ballotingRegAgreement: "KAITAK抽籤協議",
    facility_label_equipment_fee: "附加項目費用",
    "Coupon_Type": "優惠券類型",
    "Coupon_Expire_Date": "到期日",
    "programme.coupon.code": "優惠碼",
    "programme.coupon.value": "優惠券金額",
    "coupon_tips": "* 每次預訂僅可使用 1 張優惠券，剩餘金額可於下次預訂使用。",
    terms_and_conditions: "條款及細則",
    readParQ: "我已經閱讀並接受了PAR-Q協議。",
    facility_terms_and_conditions: "我已閱讀並接受條款及細則。",
    selected_slots: "已選擇時段",
    "payment.AvailableKTSPpoints": "KTSP 可用積分",
    "Use_All_Points": "使用所有積分",
    "facility_available_ktsp_points": "可用KTSP點",
    "myProfile_my_player_list": "我的參加者列表",
    pay_success_tips2: "了解更多運動的最新消息",
    ktsp_app: "KTSP APP",
    iamsmart_timeout: "響應超時，請重試",
    octopusNo: '八達通號碼',
    amountDeducted: '扣除金額',
    remainingValue: '餘額',
    lastChargeTip: '上一次於{time}{type}',
    chargeTypeMap: {
        Cash: '現金',
        Online: '線上',
        Refund: '退款',
        AAVS: '自動增值',
        Others: '其他'
    },
    payMethodMap: {
        OCTOPUS: '八達通',
        VISA: '信用咭',
        UNION_PAY: '信用咭',
        ALIPAY: '支付寶 HK',
        WECHAT_PAY: '微信支付'
    },
    paymentRefNumber: '交易編號',
    next: '下一步',
    date_time: '日期/時間',
    shop_no: '店號',
    device_no: '機號',
    "programme_form_activity_Id": "活動編號",
    "programme_form_No_of_Class": "節數",
    "programme_form_Pass_Availability": "通過可用性",
    "programme_group": "組",
    "programme_match_group": "匹配組",
    "playerList_select_tips": "*您必須至少選擇{label}名參加者",
    "playerList_attendee_list": "出席名單",
    "Attendee": "出席者",
    "playerList_child_list": "兒童名單",
    "playerList_friend_list": "好友名單",
    "playerList_type_BOOKER": "預訂者",
    "playerList_type_CHILD": "兒童",
    "playerList_type_FRIEND": "好友",
    "playerList_overage": "超齡",
    "attendee_subTitle_fixed": `此活動適合 {age} 歲 人士參加。預訂者在報名時可添加 {numAttendee} 位參加者。`,
    "attendee_subTitle": `<p>您可以在此處管理與會者。</p>
    <p>要使用學生折扣，請更改身份優惠選擇。</p>`,
    "attendee_add_you": "您",
    "ivtLimit": {
        Incompatible: "不一致",
        Incompatible_tip: `課程開始時，參加者年齡或性別不符合要求。\n
請選擇其他課程。`
    },
    "equip_title": '租賃設備',
    "payment.nodata": "沒有數據",
    "payment.noImg": "沒有圖片",
    "Emergency_0": "主要",
    "Emergency_1": "次要",
    "Emergency_Contact": "緊急聯絡人",
    "Guardian_Contact": "監護人聯絡",
    "general_email_tips": "請填寫有效的電郵地址。",
    "facility_relationship": "關係",
    "Emergency_Contact_tips":"通過新增主要和次要緊急連絡人來確保安全。為意外情况提供重要資訊。",
    "Guardian_Contact_tips":"通過新增主要和次要监护連絡人來確保安全。為意外情况提供重要資訊。",
    "facility_relations_number": "手提號碼",
    "facility_contact_email": "電郵地址",
    "general_label_name": "名稱",
    "general_label_Type": "出席者身分",
    cch_venue: "場地",
    "Personal_Coupon": "個人優惠券",
    "facility_ktsp_points": "使用 KTSP 積分",
    "facility_ktsp_points_discount": "KTSP 積分折扣",
    "facility_coupon_code": "優惠券",
    "facility_coupon": "優惠券",
    programme_age_limit: "年齡限制",
    programme_form_Date_of_Enroll: "報名日期",
    programme_form_Venue: "場地",
    programme_form_Coach: "教練",
    programme_form_Language: "語言",
    programme_form_Price: "費用",
    programme_form_Quota: "名額",
    programme_form_Date: "日期",
    programme_form_Class_detail: "時段",
    programme_class_id: "編號",
    facility_time: "時間",
    session_8_day_tip: "* 第{cchfcfs}天僅供查看",
    facility_date: "日期",
    booking_slots_tips_typec: "您需要預訂 {numOfClass} 個時段",
    facility_button_add: "新增",
    general_label_select_day_time: "選擇日期及時段",
    morning_session: "上午時段",
    afternoon_session: "下午時段",
    evening_session: "晚上時段",
    booking_slots_tips_repetitive: "發現重複的時段",
    general_label_select: "選擇",
    booking_slots_tips_choose: "請選擇時段",
    notEnoughtActivity: "沒有足夠的活動配額可供註冊",
    "add_Guardian": "新增監護人聯絡",
    "add_Emergency": "新增緊急聯絡",
    general_button_save: '儲存',
    general_button_cancel: '取消',
    facility_button_confirm: '確認',
    facility_button_delete: '删除',
    "facility_session": "時段",
    "choose_available_session": "選擇時段",
    programme_choose_group: "選擇分組",
    programme_choose_match_group: "選擇匹配組",
    programme_choose_a_class: "選擇班級",
    application_received: '申請成功',
    Guide_winners: "中簽指南",
    balloting_Id: "預訂編號",
    facility_on_blacklist: "對不起，您被列入黑名單了",
    "wr_exceed_waiting_capacity": "已超出等候人數限制。",
    "wr_no_permits_request": "請求未被允許，請登出帳戶並重新登入。",
    facility_ballot: "抽籤",
    "facility_label_payment_method": "付款方式",
    "facility_label_octopus": "八達通付款",
    "facility_label_payment_time": "付款日期/時間",
    "facility_label_Reference_Number": "交易編號",
    "facility_points_used": "積分使用",
    "facility_payment_summary": "付款詳情",
    "attendee_add_ons": "附加項目",
    "attendee_add_on_description": "您可以從附加項目清單選項中選擇租用不同類型的裝備",
    general_label_person: ' 位',
    "booking_success": "預訂成功",
    "booking_success_checkin": "簽到時間為 {date} {label} ",
    "booking_you_can_also_go": "確認電郵將發送到你的登記電郵。你也可以登入啟德體育園網站或手機應用程式，前往「帳戶」查看詳細資訊。",
    "programme_checkout_message": "{name} 活動的訂單尚未付款，請先完成付款。",
    "no_write_emergency": "請填寫緊急聯絡人資料。",
    "no_write_guardian": "請先填寫監護人的資料。",
    "noSelect_attendee_tip":"請選擇參加人",
    "checkAgreement": "請向下滾動並仔細閱讀所有條款與細則。",
    "checkParQAgreement": "結帳前請先勾選PAR-Q協議。",
    "checkAgreement_ballot": "請在提交前先細閱啟德預訂協議。",
    "checkParQAgreement_ballot": "提交前請先勾選PAR-Q協議。",
    "register_success_tip": "感謝您在KTSP預訂系統上創建帳戶。您的注册現已完成，可以開始預訂場地和節目。",
    "facility_peak_hour": "繁忙時段",
    "max_attendees": "最多选择{num}名參加者。",
    "min_attendees": "至少选择{num}名參加者",
    "select_rate_tip":"選擇價格將直接影響價格",
    cch_select_venue: "選擇埸地",
    "Selected_Slot":"已選時段",
    greeting1GM: '早安',
    greeting1GA: '午安',
    greeting1GE: '晚安',
    programe_category: "類別",
    programe_sport_type: "運動類型",
    general_label_reset: '重設',
    choose_dates: '選擇日期',
    flexible_dates: '自訂日期範圍',
    programe_today: '今天',
    programe_tomorrow: '明天',
    programe_thisWeek: '這週',
    programe_nextWeek: '下週',
    programe_nextMonth: '下個月',
    programe_allTime: '所有日期',
    "failed_login": "登錄失敗",
    octopus_device_id: '八達通機號',
    octopusAddMount: '八達通最後充值金額',
    octopusAddTime: '八達通最後充值時間',
    "all":"全部",
    octopus_add_value_info: '上一次於{date}{type}增值',
    cash: '現金',
    online: '網上',
    aavs: '自動',
    general_validation_error_invalid_value: '請輸入有效的{field}',
    "programe_age_all": '全部',
    "programme_age_children": "小童（3-11歲）",
    "programme_age_youths": "青少年（12-17歲）",
    "programme_age_adults": "成人（18-59歲）",
    "programme_age_seniors": "長者（60歲或以上）",
    norealname_user_tips: "預訂前，請先登入啟德體育園網站或手機應用程式，並完成實名認證，讓您輕鬆享用我們的各項設施及服務。",
    retrieval_ref_num: "檢索參考號碼",
    terminal_id: "終端機編號",
    merchant_id: "商戶編號",
    approval_code: "授權碼",
    entry_mode: "輸入方式",
    card_no: "卡號",
    expiration_date: "有效期限",
    emv_applic_id: "EMV訂單編號",
    emv_tranc_crypto: "EMV交易密碼",
    retry_btn: '重試',
    retry_ops: '<br>請重試(八達通號碼 {opsNo})',
    tranc_type: '交易類型',
    APP: 'APP',
    AID: 'AID',
    TC: 'TC',
    ATC: 'ATC',
    SALE: '銷售',
    NoOfSession: '節數',
    eroll_notes: '報名資訊',
    facility_real_title: '所有人士必須先完成身份認證，方可預訂設施和報名參加活動。簽到時，預訂者需出示身份證明名件核對個人身份。',
    real_name_content: '請完成實名驗證，以便繼續預訂流程。所有收集的個人資料只會用作預訂設施及報名參加活動之用。',
    verify_hkid: '香港身份證驗證',
    confirm_details_manually: '手動輸入',
    Customer_Details_title: '個人資料',
    real_name_verify: '此預訂需要實名驗證',
    real_name_verify_content: '您的姓名必須與身份證明文件上的姓名相符。一經確認，此資料將無法修改。',
    real_name_verify_tips: '*確保輸入的名字與身份證件上的名字匹配。',
    confirm_real_name: '確認個人資料？',
    confirm_real_name_content: '確保輸入的名字與身份證件上的名字匹配。',
    confirm_real_name_content_red: '此信息確認後無法編輯。',
    scan_hkid_title: '掃描您的身份證',
    scan_hkid_tips: '將您的香港身分證放入掃描區域，如下圖所示。',
    real_name_form_tips: '*您的姓名應與身份證明文件上的姓名壹致。',
    court: "場號",
    real_first_name_place: '名字 (必須與身份證明文件相符)',
    real_last_name_place: '姓氏 (必須與身份證明文件相符)',
    facility_balloting_guide: '注意事項',
    general_label_select_date: "選擇日期",
    selected_session: "已選時段",
    select_session_tips: "請選擇 {num} 個時段。",
    "no_availabel_session": "無可預訂時段",
    real_name_content_tips: "您的姓名必須與身份證明文件上的姓名相符。一經確認，此資料將無法修改。",
    booking_info: "預訂資料"
};
