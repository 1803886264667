export default {
    facility: "设施",
    venus: "主场地",
    balloting: "抽签",
    book: "预订",
    selectDate: "选择日期",
    selectYourFriend: "选择您的好友",
    age: "年龄",
    location: "地址",
    sex: "性别",
    selectCourt: "社区公共运动场",
    selectCourt_price: "$120每小时",
    roomA: "房间A",
    roomB: "房间B",
    roomC: "房间C",
    available: "可预约",
    select: "已选择",
    unavailable: "不适用",
    lessThan7days: "所选内容现在不可用，请选{cchfcfs}天以内预订的场地。",
    unavailable1: "不适用",
    peakHour: "繁忙时段",
    selectCourt_tips:
        "会场内空调正在维修中，不便之处，敬请谅解",
    tapYourOctopus: "轻触您的八达通",
    scanQRcode: "扫描您的支付宝二维码",
    presentQRcode: "出示您的付款二维码",
    invalidQRcodeTips: '二维码无效或未到的签到时间。',
    tryAgain: "重试",
    tapYourCreidtCard: "轻触您的信用卡",
    paymentFailed: "支付失败",
    countryName: "中国",
    bookingDetail_addPartner: "添加搭档",
    bookingDetail_addPartner_btn: "添加一个搭档",
    bookingDetail_addEquipment: "添加设备",
    bookingDetail_sRate_txt: "标准价格",
    bookingDetail_cRate_txt: "设备故障率",
    bookingDetail_equipment_txt: "设备",
    bookingDetail_coupon_txt: "优惠券",
    bookingDetail_subtotal_txt: "小计",
    bookingDetail_details_txt: "详情",
    programmeDetail_Introduction: "介绍",
    bookingDetail_tips1: '至少 ',
    partnerLimit: "至少{num}个队友",
    cancel: "取消",
    confirm: "确认",
    backToHome: "回到首页",
    reselect: "重新选择",
    cnacelAndLogout: "取消并退出",
    thanksForOrdering: "感谢订购",
    takeReceipt: "请拿好您的收据",
    ordering_tip:
        " 说明：您可以登录网站或应用程序查看您的订单。",
    timeLeft: "在 <span>{leftTime}</span> 秒内没有任何操作则关闭",
    timeLeft1: "<span>{leftTime1}</span>秒关闭",
    programmeDetail_lesson: "课程表",
    programmeDetail_age: "年龄",
    programmeDetail_date: "日期",
    programmeDetail_location: "地址",
    programmeDetail_selectClass: "选择类别",
    programmeDetail_enrolled: "登记",
    programmeDetail_selectcoupon: "选择优惠券",
    programmeDetail_selectcoupon_tips: "您有 {couponnum} 个可用优惠券",
    programmeDetail_more: "更多",
    programmeDetail_title: "预订详情",
    programmeDetail_id: "预订编号",
    programme: "社区活动列表",
    programmeSport: "课程",
    sport: "体育运动",
    Sport: "体育运动",
    sports: "体育运动",
    month: "月份",
    coach: "教练",
    programmeDetail_coach: "教练",
    filter: "筛选",
    more_filter: "筛选",
    searchPlaceholder: '请输入体育运动、教练...',
    search: "搜索",
    pickSport: '体育设施列表',
    selectSports: "选择运动",
    selectMonth: '选择开始月份',
    viewAll: '查看全部',
    enroll: '现在报名',
    viewMore: '查看更多',
    enjoyInKaiTak: '在启德尽情享受',
    checkIn: '签到',
    selectOneSport: '选择一项运动',
    programmeDetail_time: "时间",
    programmeDetail_price: '价格',
    bookingDetail_discount_txt: "折扣",
    contact_us: "联系我们：",
    menu: "菜单",
    home: "首页",
    minutes: '备忘录',
    total: '总计',
    loginTip: "登入",
    login: '登入',
    back: '返回',
    Back: '返回',
    realNameTop: '实名验证',
    realNameTip: '抽签必须是实名制的。实名信息仅用作活动的认证数据，启德不会用于其他目的。',
    password: "密码",
    emailPhone: "电邮地址/手机号码",
    registerByIam: "iAM Smart注册",
    loginByIam: "iAM Smart登入",
    byIam: "使用iAM Smart",
    hkId: "香港身份证",
    scanHkId: "扫描您的香港身份证",
    scanHkId1: "扫描您的香港身份证",
    or: '或者',
    scanTip: "将您的香港身份证放在扫描区，如下所示",
    noAccount: "新用户？",
    register: "注册",
    hk_id: '身份证号',
    birthday: "生日",
    phone: "手机号码",
    phoneNo: '手机号码',
    name: '姓名',
    enterId: "输入您的电邮地址/手机号码",
    enterPsd: "密码",
    errorAccount: "电邮地址地址或者手机号码错误",
    errorAccountEmail: "请输入有效的电子邮件地址",
    errorAccountPhone: "请输入有效的手机号码",
    errorPwd: "您的密码必须包含:* 8个或更多字符;*大小写字母;* 3个特殊字符。",
    differPsd: "密码与新密码不匹配",
    duplicateEmailOrPhone: "电邮地址或者手机号码已存在",
    hkidHasUsed: "该香港身份证已经被注册",
    viewDetail: "登录网页端或者APP查看详情",
    loginNow: "该香港身份证已经注册，正在登录",
    scanQrCode: "扫描二维码",
    stepBelow: "请根据以下步骤操作:",
    openIam: "1.在你的手机上打开iamSmart应用;",
    clickScanBtn: "2.点击扫描按钮;",
    scanPage: "3.扫描页面上的二维码;",
    downloadIam1: "没有iamSmart应用请下载",
    downloadIam2: "还没有iamSmart应用,扫描二维码马上下载!",
    confirmPsd: "密码确认",
    idPlaceholder: "输入电邮地址/手机号码",
    ssoEmailPlaceholder: "email{'@'}email.com",
    ssoEmail: "电邮地址",
    ssoPhone: "手机号码",
    ssoPhonePlaceholder: "输入手机号码",
    ssoEmailTab: "电邮地址",
    ssoPhoneTab: "手机号码",
    checkInTip: "扫描香港身份证或者二维码",
    checkInTip2: "在扫描区域放入应用程序的二维码或香港身份证",
    checkInSucc: "签入成功！",
    checkInFail: "签入失败",
    timePending: "等待签入",
    scanAgain: "扫描失败，请重新尝试扫描香港身份证",
    failReason: "对不起，没有找到您的预订信息",
    printReceipt: "打印收据",
    amount: "金额 ",
    notice_txt1: "请在 时间内完成订单支付",
    tips_txt1: "在 时间内没有操作则关闭",
    tips_txt2: " 秒关闭",
    payCountdown: "请在 <span>{num}</span> 分钟内完成您的预订",
    seconds: " 秒",
    print_txt: "打印中...",
    print_fail: "打印失败",
    print_wrongdes: "对不起，打印失败的原因是",
    print_sucess: "打印成功",
    print_take_receipt: "请拿好您的收据。",
    payment_wrongdes: "对不起，付款失败的原因是",
    payment_change_method: "修改付款方法",
    payment_select_method: "选择付款方式",
    payment_method_txt1: "信用卡",
    payment_method_txt2: "支付宝/微信支付",
    payment_method_txt3: "八达通支付",
    noActivity: "今天没有活动",
    ok: "确定",
    justOneHour: "对不起，高峰时段只有一个小时可用",
    close: "关闭",
    programme_title: "活动详情",
    quota: "名额",
    venue: "场地",
    left: "剩余",
    leftNum: "剩余名额 : {num}",
    lessonInfo: "{num} 节课",
    ageRange: "{min}-{max} 岁",
    ageAbove: "{age} 岁以上",
    ageBelow: "{age} 岁以下",
    bottomPrice: "费用",
    selectCoach: "选择教练",
    searchCoachName: "选择一个教练的名字",
    searchProgramme: "请输入运动名称，教练名称...",
    sportsType: "运动类型",
    clearAll: "全部清除",
    enroll_now: "现在报名",
    book_now: "现在预订",
    venue_Details: "场地详情",
    mainStadium: "主场馆",
    change: "更换",
    full: "已满",
    operation: "活动",
    hotline: "电话热线",
    lesson: "{num} 课时",
    lessons: "{num} 课程",
    parQ_age_limit: "您预订的项目需要参与问卷调查，请到web或者app中完成后再进行预订",
    parQ_answer_all: '请回答所有问题',
    parQ_tips_option1: "我确认我已经过医生检查，并证明我的身体状况适合参加这些活动。",
    parQ_tips_option2: "需要选择出示健康证明书，以证明本人身体健康，可参加员工的健身活动。我要到柜台去进一步核实一下。取消确认。",
    parQ_title70: "身体活动问卷调查(PAR-Q&You)",
    parQ_tips_option70_1: "我经常参加体育活动，有能力参加体育活动。因此，我不需要出示任何医学证明来证明我有能力参加本次活动，我明白如果我对自己的能力有任何怀疑，我应该在参加活动前咨询医生。",
    parQ_tips_option70_2: "我不经常参加体育活动。但是，我已经接受了医生的检查，并证明我的身体状况适合参加本次活动，我将在申请提交期间上传一份医疗证明。",
    parQ_tips_option70_3: "我特此确认：",
    parQ_tips_option70_4: "我已经阅读、理解并完成了这份问卷。我的任何问题都得到了令人满意的回答。",
    parQ_tips_decline: "拒绝",
    parQ_tips_agree: "确认并同意",
    virtual_queue_title: '虚拟排队',
    virtual_queue_tips1: '您在一个虚拟队列中。',
    virtual_queue_tips2: '人很多，请耐心等待...',
    virtual_queue_tips3: '抱歉久等',
    virtual_queue_tips4: '目前需求量非常大，因此您可能需要比平时等待更长的时间才能确认预订请求。',
    checkin_tips1: "身份证号码不正确",
    checkin_tips2: "纸质身份证",
    courtPrice: "${num}/小时",
    yes: "是",
    no: "否",
    reg_declaration: "注册声明",
    reg_scan: "请通过扫描香港身份证填写以下表格",
    reg_scan_tip: "将您的香港身份证放在扫描区，如下所示",
    manuallyEnter: "手动输入",
    register1: '注册',
    confirmInfo: "确认您的信息",
    registerTip: '*该联系人将用作您的登录帐户。',
    registerByHKID: "香港身份证注册",
    registerByEmail: "电邮地址/手机号码注册",
    registerAction: '执行注册',
    emailVerification: "电邮地址验证",
    finishSoon: "很快即将完成！",
    oneStep: "还有一步即可成为KAITAK会员。",
    emailAddr: "电邮地址",
    emailPh: "输入您的电邮地址地址",
    verCodeErr: "验证码错误或超时。",
    hasSend: "验证码已发送到您的电子电邮地址 {email}, 请输入",
    registerFail: "注册失败",
    regSucc: "注册成功！",
    sendPassword: "初始密码已发送到电子邮件地址 <span>{email}</span>.",
    login_web: "为了您的帐户安全，请登录网站更改密码并补充您的个人信息。",
    emailErr: "电邮地址地址错误",
    send: "发送",
    sending_limited: "同一个电邮地址发送超出最大限制。",
    emailOccupied: "您的电邮地址已经被占用，您可以通过忘记密码流程找回账号。",
    serverErr: "系统错误，请稍后再试。",
    print_paper_will_run_out: "纸将用完。",
    print_failed: "打印失败。",
    swipe_octopus: "刷您的八达通卡",
    retry_please: "请重试",
    octopus_swipe_tip_1: "请把您的八达通放在卡片区",
    octopus_swipe_tip_2: "",
    octopus_swipe_tip_3: "无操作会自动取消",
    octopus_swipe_tip_4: "请再次拍卡！",
    octopus_swipe_tip_5: "交易未能完成。请再次拍卡！",
    octopus_error_tip_1: "此卡失效。请使用另一张八达通卡！",
    octopus_error_tip_2: "卡内余额不足，交易取消。请先行增值！",
    octopus_error_tip_3: "卡上储值额超出上限，请使用另一张八达通卡！",
    octopus_error_tip_4: "机器故障。 请联系工作人员寻求帮助！",
    link_octopus: "正在连接八达通…",
    tap_octopus: "放上八达通",
    confirmation_successful: "确认成功。",
    declaration_title: "设施预订声明",
    login_input_username_password: "请输入 电邮地址/手机号码 或者 密码",
    login_repeat_submit: "请勿重复提交",
    firstName: "名字",
    name_is_requeird: '此字段为必填项。请确保输入的姓名与身份证明名件的姓字相符。',
    lastName: "姓氏",
    gender: "性别",
    idCardType: "证件类型",
    idCardNum: "证件号码",
    birth: "生日",
    notEmpty: "{property}不能为空",
    male: "男",
    female: "女",
    any: "不限",
    other: '其他',
    scanFailed: "身份证账号扫描获取失败",
    noCourt: "无可预订时段",
    searchMember: "查询会员名称",
    enterVcode: "输入验证码",
    noData: "未查询到数据",
    morning: "早上时段",
    afternoon: "下午时段",
    evening: "晚上时段",
    avail: "剩余",
    normal: "正常",
    less: "较少",
    partnerUpperLimit: "您最多只能添加{num}个伙伴",
    partnerLowerLimit: "您至少需要添加{num}个伙伴",
    duplicatePartner: "您已经添加了{partner}",
    deleteFail: "删除失败",
    selectRate: "特殊人群优惠选项",
    class: "班级",
    classDetail: "班级详情",
    selectSport: "运动场所",
    Venue: "场地",
    Fee: "费用",
    selectVenue: "选择场地",
    guardian: "监护人",
    eCouponCode: "电子优惠券码",
    this_coupon_code: "此優惠碼無效或已過期。",
    eCouponCodePlaceholder: "请输入优惠码",
    serialNo: "券码",
    "facility_apply": "申请",
    "facility_check": "兑换",
    valid: "有效期",
    contactName: "联系人姓名",
    relationship: "关系",
    guardianPhone: "监护人电话",
    guardianEmail: "监护人电邮地址",
    add: "添加",
    delete: "删除",
    selectRateType: "价格选项",
    confirmBooking: "确认预订",
    nameLength: "长度为1-32个字符",
    contactNamePlaceholder: "请输入监护人姓名",
    relationshipPlaceholder: "请选择监护人关系",
    guardianPhonePlaceholder: "请输入监护人电话",
    guardianEmailPlaceholder: "请输入监护人电邮地址",
    guardianEmailPlaceholderType: "请输入正确的电邮地址地址,长度为1-128个字符",
    phoneLength: "请输入8位纯数字",
    contactPhone: "联系人电话",
    contactEmail: "联系人电邮地址",
    completeOrder: "完成订单获得",
    points: "积分",
    regAgreement: "KAITAK注册协议",
    checkAgreementRegConfirm: "在确认前请先勾选KAITAK注册协议",
    bookingRegAgreement: "条款及细则",
    regAgreementTips: "请仔细阅读",
    regAgreementTips1: "我已阅读并接受条款和条件",
    enterEmailOrPhone: "输入您的电邮地址/手机号码",
    enterEmail: "输入您的电邮地址",
    enterAny: "输入您的{label}",
    enterPhone: "输入您的手机号码",
    question: "问题",
    YES: "是",
    NO: "否",
    selectPaymentMethod: "选择付款方法",
    creditCard: "信用卡",
    fps: "快速支付 / 支付宝/ 微信 / 汇丰",
    payMethod: "付款方法",
    bookingNotes: "预订须知：",
    booking_Note: "注意事项：",
    bookingNotes_1: "1.每次预订只能预订一个场馆。在高峰时段，最多可预订2小时的场地，而在非高峰时段，最多可预订4小时的场地。一旦您的预订得到确认，我们将不会退还任何退款。",
    bookingNotes_2: "2.非吸烟场所严禁吸烟。",
    bookingNotes_3: "3.请穿着合适的运动服和运动鞋参加比赛。",
    bookingNotesCon: "每次预订限制为1个场馆，高峰时段每个球场限制为2小时，非高峰时段限制为4小时。一旦预订，恕不退款，请小心！2.禁止吸烟的场所，严禁在场所内吸烟。请穿着合适的运动服和运动鞋比赛。",
    selectPaymentMethodTips: "请选择付款方法。",
    receiptNo: "收据号码",
    bookingNumber: "预订编号",
    octopus: "八达通",
    logout: "登出",
    notes: "须知",
    checkInTimeStartTip: "预订时间从",
    checkInTimeEndTip: "通过扫描二维码开始。您可以登录网站或应用程序查看您的预订详情。",
    paymenCodeTip: '请扫描二维码以启动付款流程。付款成功处理后，单击“确认”按钮完成预订。',
    payment: "付款",
    bookingNotes1: "您的个人信息仅用于注册为启德体育公园会员。启德体育乐园不会将您的个人资料用于其他目的。一个身份可以注册一个帐户。",
    bookingNotes2: "非会员登录将自动注册为会员。",
    loginNote: "所有收集的个人资料将仅用于注册目的。除非法律允许或要求，否则未经您事先同意，启德体育公园不会向任何第三方披露您的个人资料。",
    guardian_name: "监护人姓名",
    ballot_history_notes: "以下是对抽签结果的各种解释，每次预约仅限于一个场地，高峰时段每个场地限制2小时，非高峰时段限制4小时。一经预订，恕不退款，请谨慎使用!2. 场地禁止吸烟，场内严禁吸烟。",
    addEquipment: "添加器材",
    pointsDeduction: "*每{point}积分可以抵扣HK${price}",
    playerName: "姓名",
    phoneNumber: "手机号码",
    email: "电邮地址",
    memberId: "会员ID",
    addPlayer: "添加参加人",
    programmeDetailSelect: "选择",
    emergencyContact: "紧急联系人",
    ktspPoints: "KTSP 积分",
    total1: "总计",
    results: "条结果",
    confirmDelTips: "您想要删除这条数据吗?",
    multipleSport: "此运动需要多人参与，请前往web端预订。",
    noVenue: "未查询到场馆或房间",
    peakHourLimit: "高峰时段的预订超过了上限，请点击「确定」按钮以继续您的预订。",
    notPeakHourLimit: "非高峰时段的预订超过了上限，请点击「确定」按钮以继续您的预订。",
    HourLimit: "预订的时段超过了上限，请点击「确定」按钮以继续您的预订。",
    onlyOneCourtPerPeriod: "每个时间段只能选择一个场地",
    onlyOneCourtForBallot: "每个人每个时段只能参与抽签一次。",
    courtNeedSameDay: "您只能选择同一天的场地",
    oneCourtLimit: "请至少选择一个场地及时段。",
    withoutCheckIn: "由于您多次预订成功未签到，当前无法预订场地。",
    uncheckParQ: "请勾选parQ信息!",
    noMoreCoach: "没有更多了",
    howtoBallot: "如何进行抽签",
    ballotingHistory: "抽签历史",
    ballotingDes1: "使用您的HKID或iAM Smart验证您的真实姓名，即可参与投票过程。选择您喜欢的日期和时间，然后提交您的条目。一旦投票结果出来，获胜者将收到一封电子邮件通知，允许他们继续付款并确认预订。",
    ballotingDes2: " 或者 ",
    ballotingDes3: "。 选择你喜欢的课程，点击 ",
    ballotingDes4: " 按钮",
    enroll_ballot: "抽签报名",
    ballotingTips: "*抽签必须是实名，请扫描您的HKID或使用iAM Smart登录。",
    programmeBallot: "课程抽签",
    ballotNotice: "请核对信息，VBS只会将以下显示信息保存为注册数据。",
    guardian1: '监护人1',
    guardian2: '监护人2',
    partnerLimitWarnTxt: "参加人数不能大于{max}",
    realnameBookTips: "预订前，请先登录启德体育园网站或手机应用程序，并完成实名认证，让您轻松享用我们的各项设施及服务。",
    leastOneGuar: "至少要有一个监护人",
    noConfigAmount: "该课程未配置支付金额信息",
    facilityBallot: "预订抽签",
    ballotingDetails: '抽签信息',
    announceDate: '公布日期',
    submitSccessful: '提交成功!',
    language: '语言',
    languages: '语言',
    importantInfo: '重要信息',
    howToPlay: '如何玩',
    selectTime: '选择时间段',
    idNo: '证件号',
    ballotingTip: '抽签结果将于 ',
    ballotingTip1: '公布。要查看您的投票详情和结果，请登录我们的网站或应用程序。',
    balloting_download: '下载应用程序以探索更多体育项目并了解最新信息。',
    downloadApp1: '下载应用程序',
    downloadApp2: '以了解更多信息并获取最新信息',
    vote: '*抽签必须是实名制，请扫描您的香港身份证或通过iAM Smart登录。',
    confirmBalltoing: '请检查信息。VBS将只保存显示的以下内容。',
    ballotingResult: '抽签结果',
    ballotingDate: '抽签日期',
    bookingFailed: '预订失败',
    bookingFailedReason: '对不起，预订失败的原因是未配置价格。',
    viewResult: "查看结果",
    confirmBallotTitle: "确认您的抽签",
    general_label_is_requeird: "{label}是必填项",
    realNameBookingTips: "此场地需要香港身份证。请在预订前完成身份验证过程。",
    realNameBookingTipsSports: "选定的体育项目需要香港身份验证，请在预订前完成验证过程。",
    queueTimeout: "排队超时,请重新排队",
    Repay: "重新支付",
    declarParq: "体能活动适应能力问卷 (PAR-Q)",
    parqYesTips: "Par-Q信息未满足预订要求，无法继续预订",
    addOnsFee: "附加设备费用",
    requiredTips: "必填信息",
    noMoreData: '没有更多数据了',
    time_am: '上午',
    time_pm: '下午',
    noAgeLimit: '无年龄限制',
    thankPay: '感谢您的',
    checckPay: '请检查您的电子邮件，了解',
    bookDetail: '您预订的详细信息。',
    exit: '退出',
    continue: '继续',
    checkEmailTips: '请查看您的电子邮件以了解您的预订详细信息。',
    logout_upcase: "退出",
    welcome_username: '欢迎! ',
    hi_username: "嗨，{username}。",
    reminder_time: "您尚余{remderTime}分钟完成预订。",
    octopusErrorTips1: '机器故障。请与本店职员联络。',
    octopusErrorTips2: '此卡失效。请使用另一张八达通卡。',
    octopusErrorTips3: '卡内余额不足，交易取消。请先行充值。',
    octopusErrorTips4: '卡上储值額超出上限，请使用另一张八达通卡。',
    octopusErrorTips5: '请再次拍卡。',
    octopusErrorTips6: '错卡，请重试(八达通号码{num})。',
    octopusErrorTips7: '读卡错误，请重试。',
    payTimeOut: "我们无法处理您的付款。请稍后再试。",
    errorPwdOrEmail: "您输入的电邮地址 / 手机号码或密码错误。",
    errorGuardiansTips: "请填写监护人的信息以完成预订。",
    errorEmergencyContactTips: "请填写紧急联系人信息以完成预订。",
    posPaymentCode: {
        '00': '交易获得批准。',
        '01': '呼叫卡中心获取审批码。',
        '02': '呼叫卡中心获取审批码。',
        '03': '请查询卡中心',
        '04': '没收此卡',
        '05': '不接受',
        '51': '交易拒绝。',
        '41': '卡丢了。',
        '43': '偷来的信用卡。',
        'BB': '终端忙或不可用。',
        'OT': 'S9000输入超时。',
        'CN': '被接线员取消了。',
        'LC': '通信失败。',
        'NC': '连接失败。',
        'TO': '响应超时。',
        'RF': '密码失败',
        'NT': '无批量交易',
        '78': '找不到指定的记录',
        'NA': '不允许授权',
        'NJ': '不允许调整',
        'NM': '不允许手动输入',
        'NO': '不允许线下销售',
        'NR': '不允许退款销售',
        'NV': '不允许无效销售',
        'CB': '请结算，因为记录内存已满',
        'VD': '交易无效',
        'AN': '无法找到主机或收购方',
        '77': '这发生在香港银行主机上，不会批量上传和打印结算单。ECR应将此视为批量加载情况',
        'OR': '设计是否执行覆盖事务',
        'NP': '表明打印机没有纸，并要求取消',
        'XX': '手机没有放在基座上',
        'ME': 'MAC的错误',
        'EP': '终端处于反转等待模式',
        'UC': '不支持的卡片类型',
        "PYE": "支付类型错误",
        "OCF": "创建支付连接异常",
        "SE": "系统异常",
    },
    errorIamSmartSq: '未获取到信息，请重试。',
    selectPayMethod: '结账前请先选择付款方式。',
    proceedCheckout: '去结算',
    unCompleteEmergency: '在结算前请先完成紧急联系人信息。',
    unCompletecheckParQ: "在结算前请先勾选parQ信息。",
    unCompleteGuardiansTips: "在结算前请先完成监护人的信息。",
    unCompletecheckParQConfirm: "在确认前请先勾选parQ信息。",
    checkAgreementConfirm: '在确认前请先勾选KAITAK预订协议。',
    existYesParQ: '强烈建议对PAR-Q回答“是”的参与者在继续之前寻求医疗指导。你确定要继续吗？',
    booking_summary: "预订详情",
    "booking_slots": "预订时段",
    no_more_data: '没有更多数据了',
    loading_data: '加载中',
    check_out:"付款",
    Check_Out:"付款",
    facility_menu:"体育设施预订",
    programmeSport_menu:"社区活动",
    checkIn_menu:"签到",
    points_earned: "积分赚取",
    "Select_Time":"选择时间",
    submit: "提交",
    back_txt:"返回",
    bookingChangeTime: {
        threeTime: '每次最多可预订3个同一天的时段，其中仅限1个高峰时段。',
        clickTips: '点击"确认"继续预订，先前选择的时段将被清除。'
    },
    available_tomorrow: "明天可订",
    facility_choose_session: "选择时段",
    notes_enrolment: {
        title: "报名须知",
        desc1: "1.用户可透过启德体育园预订系统递交运动设施的抽签申请。",
        desc2: "2.每名用户于每个抽签日期只可递交一个运动设施一个时段的申请。",
        desc3: "3.如有重复递交表格或填报数据不齐者，其抽签资格将被取消。",
        desc4: "4.中签者须于指定期限内透过指定网站或手机软件完成付款手续，逾期未完成付款当中签者自愿放弃处理。"
    },
    programme_choose_class_tip: '请选择班级',
    facility_full: "已满",
    facility_available: "可预订",
    facility_shortage: "名额有限",
    opstips: '交易正在进行中，请稍候...',
    opstips2: '付款尚未成功，参考代码100022，<br>请重试或取消此付款请求',
    opsdownloadTips: '数据正在同步。<br>请勿执行其他操作。',
    ballotingRegAgreement: "KAITAK抽签协议",
    facility_label_equipment_fee: "附加项目费用",
    "Coupon_Type": "优惠券类型",
    "Coupon_Expire_Date": "到期日",
    "programme.coupon.code": "优惠码",
    "programme.coupon.value": "优惠券金额",
    "coupon_tips": "* 每次预订仅可使用 1 张优惠券，剩馀金额可于下次预订使用。",
    terms_and_conditions: "条款及细则",
    readParQ: "我已经阅读并接受了PAR-Q协议。",
    facility_terms_and_conditions: "我已阅读并接受条款及细则。",
    selected_slots: "已选择时段",
    "payment.AvailableKTSPpoints": "KTSP 可用积分",
    "Use_All_Points":"使用所有积分",
    "facility_available_ktsp_points": "KTSP 可用积分",
    "myProfile_my_player_list": "我的出席者列表",
    pay_success_tips2: "了解更多运动的最新消息",
    ktsp_app:"KTSP APP",
    iamsmart_timeout: "响应超时,请重试",
    octopusNo: '八达通号码',
    amountDeducted: '扣除金额',
    remainingValue: '余额',
    lastChargeTip: '上一次于{time}{type}',
    chargeTypeMap: {
        Cash: '现金',
        Online: '线上',
        Refund: '退款',
        AAVS: '自动增值',
        Others: '其他'
    },
    payMethodMap: {
        OCTOPUS: '八达通',
        VISA: '信用卡',
        UNION_PAY: '信用卡',
        ALIPAY: '支付宝 HK',
        WECHAT_PAY: '微信支付'
    },
    paymentRefNumber: '交易编号',
    next: '下一步',
    date_time: '日期/时间',
    shop_no: '店号',
    device_no: '机号',
    "programme_form_activity_Id": "活动编号",
    "programme_form_No_of_Class": "节数",
    "programme_form_Pass_Availability":"通过可用性",
    "programme_group": "组",
    "programme_match_group": "匹配组",
    "playerList_select_tips": "*您必须至少选择{label}名出席者",
    "playerList_attendee_list": "出席名单",
    "Attendee": "出席者",
    "playerList_child_list": "儿童名单",
    "playerList_friend_list": "好友列表",
    "playerList_type_BOOKER": "预订者",
    "playerList_type_CHILD": "儿童",
    "playerList_type_FRIEND": "好友",
    "playerList_overage": "超龄",
    "attendee_subTitle_fixed": `此活动适合 {age} 岁 人士参加。预订者在报名时可添加 {numAttendee} 位参加者。`,
    "attendee_subTitle": `<p>您可以在此编辑或添加您的同伴。</p>
    <p>要使用学生折扣，请更改身份优惠选择。</p>`,
    "attendee_add_you": "您",
    "ivtLimit":{
        Incompatible:"不一致",
        Incompatible_tip: `课程开始时，出席者年龄或性别不符合要求。 \n
    请选择其他课程。`
    },
    "equip_title":'租赁设备',
    "payment.nodata": "没有数据",
    "payment.noImg": "没有图片",
    "Emergency_0": "主要",
    "Emergency_1": "次要",
    "Emergency_Contact": "紧急联络人",
    "Guardian_Contact": "监护人联络",
    "general_email_tips": "请填写有效的电邮地址。",
    "facility_relationship": "关系",
    "Emergency_Contact_tips":"通过增加主要和次要紧急联络人来确保安全。为意外情况提供重要资料。",
    "Guardian_Contact_tips":"通过增加主要和次要监护人联络人来确保安全。为意外情况提供重要资料。",
    "facility_relations_number": "手机号码",
    "facility_contact_email": "电邮地址",
    "general_label_name": "名称",
    "general_label_Type": "出席者身分",
    cch_venue: "场地",
    "facility_ktsp_points": "使用 KTSP 积分",
    "facility_ktsp_points_discount": "KTSP 积分折扣",
    "facility_coupon_code": "优惠券",
    "facility_coupon": "优惠券",
    programme_age_limit: "年龄限制",
    programme_form_Date_of_Enroll: "报名日期",
    programme_form_Venue: "场地",
    programme_form_Coach: "教练",
    programme_form_Language: "语言",
    programme_form_Price: "费用",
    programme_form_Quota: "名额",
    programme_form_Date: "日期",
    programme_form_Class_detail: "时段",
    programme_class_id: "编号",
    facility_time: "时间",
    session_8_day_tip: "* 第{cchfcfs}天仅供查看",
    "add_Guardian": "新增监护人联络",
    "add_Emergency": "新增紧急联络",
    general_button_save: '储存',
    general_button_cancel: '取消',
    facility_button_confirm: '确认',
    facility_button_delete: '删除',
    facility_date: "日期",
    booking_slots_tips_typec: "您需要预订 {numOfClass} 个时段",
    facility_button_add: "新增",
    general_label_select_day_time: "选择日期及时段",
    morning_session: "上午时段",
    afternoon_session: "下午时段",
    evening_session: "晚上时段",
    booking_slots_tips_repetitive: "发现重复的时段",
    general_label_select: "选择",
    booking_slots_tips_choose: "请选择时段",
    notEnoughtActivity: "没有足够的活动配额可供注册",
    "facility_session": "时段",
    "choose_available_session": "选择时段",
    programme_choose_group: "选择分组",
    programme_choose_match_group: "选择匹配组",
    programme_choose_a_class: "选择班级",
    application_received: '申请成功',
    Guide_winners: "中签指南",
    balloting_Id: "预订编号",
    facility_on_blacklist: "对不起，您被列入黑名单了",
    "wr_exceed_waiting_capacity": "已超出等候人数限制。",
    "wr_no_permits_request": "请求未被允许，请登出帐户并重新登入。",
    facility_ballot: "抽签",
    "facility_label_payment_method": "付款方式",
    "facility_label_octopus": "八达通付款",
    "facility_label_payment_time": "付款日期/时间",
    "facility_label_Reference_Number": "交易编号",
    "facility_points_used": "积分使用",
    "facility_payment_summary": "付款详情",
    "attendee_add_ons": "附加項目",
    "attendee_add_on_description": "您可以从附加项目清单选项中选择租用不同类型的装备",
    general_label_person: ' 位',
    "booking_success": "预订成功",
    "booking_success_checkin": "签到时间为 {date} {label}",
    "booking_you_can_also_go": "确认电邮将发送到您的登记电邮。 你也可以登入启德体育园网站或手机应用程式，前往「帐户」查看详细资讯。",
    "programme_checkout_message": "{name} 活动的订单尚未付款，请先完成付款。",
    "no_write_emergency": "请填写紧急联络人资料。",
    "no_write_guardian": "请先填写监护人的资料。",
    "noSelect_attendee_tip":"请选择参加人",
    "checkAgreement": "请向下滚动并仔细阅读所有条款与细则。",
    "checkParQAgreement": "结账前请先勾选PAR-Q协议。",
    "checkAgreement_ballot": "请在提交前先细阅启德预订协议。",
    "checkParQAgreement_ballot": "提交前请先勾选PAR-Q协议。",
    "facility_peak_hour": "繁忙时段",
    "max_attendees":"最多选择{num}名出席者。",
    "min_attendees":"至少选择{num}名出席者。",
    "register_success_tip": "感谢您在KTSP预订系统上创建帐户。您的注册现已完成，可以开始预订场地和节目。",
    "select_rate_tip":"选择价格将直接影响价格。",
    cch_select_venue: "选择场地",
    "Selected_Slot":"已选时段",
    greeting1GM: '早安',
    greeting1GA: '午安',
    greeting1GE: '晚安',
    programe_category: "类别",
    programe_sport_type: "运动类型",
    general_label_reset: '重设',
    choose_dates: '选择日期',
    flexible_dates: '自订日期范围',
    programe_today: '今天',
    programe_tomorrow: '明天',
    programe_thisWeek: '这周',
    programe_nextWeek: '下周',
    programe_nextMonth: '下个月',
    programe_allTime: '所有日期',
    "failed_login": "登录失败",
    octopus_device_id: '八达通机身号码',
    octopusAddMount: '八达通最后充值金额',
    octopusAddTime: '八达通最后充值时间',
    "all":"全部",
    octopus_add_value_info: '上一次于{date}{type}充值',
    cash: '现金',
    online: '网上',
    aavs: '自动',
    general_validation_error_invalid_value: '请输入有效的{field}',
    "programe_age_all": '全部',
    "programme_age_children": "儿童（3-11岁）",
    "programme_age_youths": "青少年（12-17岁）",
    "programme_age_adults": "成年人（18-59岁）",
    "programme_age_seniors": "老年人（60岁及以上）",
    norealname_user_tips: "预订前，请先登录启德体育园网站或手机应用程序，并完成实名认证，让您轻松享用我们的各项设施及服务。",
    retrieval_ref_num: "检索参考号码",
    terminal_id: "终端机编号",
    merchant_id: "商户编号",
    approval_code: "授权码",
    entry_mode: "输入方式",
    card_no: "卡号",
    expiration_date: "有效期限",
    emv_applic_id: "EMV订单编号",
    emv_tranc_crypto: "EMV交易密码",
    retry_btn: '重试',
    retry_ops: '<br>请重试(八达通号码 {opsNo})',
    tranc_type: '交易类型',
    APP: 'APP',
    AID: 'AID',
    TC: 'TC',
    ATC: 'ATC',
    SALE: '销售',
    NoOfSession: '节数',
    eroll_notes: '报名信息',
    facility_real_title: '所有人士必须先完成身份认证，方可预订设施和报名参加活动。签到时，预订者需出示身份证明名件核对个人身份。',
    real_name_content: '请完成实名验证，以便继续预订流程。所有收集的个人资料只会用作预订设施及报名参加活动之用。',
    verify_hkid: '香港身份证验证',
    confirm_details_manually: '手动输入',
    Customer_Details_title: '个人资料',
    real_name_verify: '此预订需要实名验证',
    real_name_verify_content: '您的姓名必须与身份证明文件上的姓名相符。一经确认，此资料将无法修改。',
    real_name_verify_tips: '*确保输入的名字与身份证件上的名字匹配。',
    confirm_real_name: '确认个人资料？',
    confirm_real_name_content: '确保输入的名字与身份证件上的名字匹配。',
    confirm_real_name_content_red: '此信息确认后无法编辑。',
    scan_hkid_title: '扫描您的身份证',
    scan_hkid_tips: '将您的香港身分证放入扫描区域，如下图所示。',
    real_name_form_tips: '*您的姓名应与身份证明文件上的姓名一致。',
    court: "场号",
    real_first_name_place: '名字 (必须与身份证明文件相符)',
    real_last_name_place: '姓氏 (必须与身份证明文件相符)',
    facility_balloting_guide: '注意事项',
    general_label_select_date: "选择日期",
    selected_session: "已选时段",
    select_session_tips: "请选择 {num} 个时段。",
    "no_availabel_session": "无可预订时段",
    real_name_content_tips: "您的姓名必须与身份证明文件上的姓名相符。一经确认，此资料将无法修改。",
    booking_info: "预订资料"
};
